import { FC } from 'react';
import { SwimlaneStyle } from 'ContentLayout';

import { H3 } from '@rikstv/shared-components';

import { MenuDetails } from '../../../forces/assetDetails/assetDetails.types';
import { UnstyledLink } from '../../../router/Link';
import { letUserAgentHandleEvent } from '../../../utils/events/event.utils';
import { getImagePackSet } from '../../../utils/imagePack/imagePack.utils';
import { AssetImage } from '../../assetImage/AssetImage';
import { AssetImageContainer } from '../../assetImage/AssetImageContainer';
import { Breakpoints } from '../../grid/breakpoints';
import { SwimlaneSettings } from '../Swimlane.types';

import './MenuSwimlaneItem.scss';

interface Props extends MenuDetails {
  selected?: boolean;
  style: SwimlaneStyle;
  href: string;
  onClick?: () => void;
  preventNavigation?: boolean;
}

interface MenuStyleConfig {
  MenuLogo: Pick<SwimlaneSettings, 'placeholderHeight'>;
  MenuThumbnail: Pick<SwimlaneSettings, 'placeholderHeight'>;
  MenuText: Pick<SwimlaneSettings, 'placeholderHeight'>;
}

export const swimlaneConfig: MenuStyleConfig = {
  MenuLogo: {
    placeholderHeight: {
      [Breakpoints.XS]: 225,
      [Breakpoints.SM]: 225,
      [Breakpoints.MD]: 225,
      [Breakpoints.ML]: 225,
      [Breakpoints.LG]: 230,
      [Breakpoints.XL]: 250,
      [Breakpoints.XXL]: 275,
      [Breakpoints.DEFAULT]: 225,
    },
  },
  MenuThumbnail: {
    placeholderHeight: {
      [Breakpoints.XS]: 265,
      [Breakpoints.SM]: 410,
      [Breakpoints.MD]: 300,
      [Breakpoints.ML]: 275,
      [Breakpoints.LG]: 275,
      [Breakpoints.XL]: 300,
      [Breakpoints.XXL]: 310,
      [Breakpoints.DEFAULT]: 310,
    },
  },
  MenuText: {
    placeholderHeight: {
      [Breakpoints.XS]: 200,
      [Breakpoints.SM]: 200,
      [Breakpoints.MD]: 200,
      [Breakpoints.ML]: 200,
      [Breakpoints.LG]: 200,
      [Breakpoints.XL]: 260,
      [Breakpoints.XXL]: 260,
      [Breakpoints.DEFAULT]: 200,
    },
  },
};

export const MenuSwimlaneItem: FC<
  Pick<Props, 'name' | 'image' | 'style' | 'href' | 'onClick' | 'preventNavigation' | 'selected'>
> = ({ name, image, style, href, onClick, preventNavigation = false, selected = false }) => {
  const selectedClass = selected ? 'menu-swimlane__item--selected' : '';
  const onClickLink = (evt: React.MouseEvent<any, MouseEvent>) => {
    if (letUserAgentHandleEvent(evt)) {
      return;
    }

    onClick?.();
    if (preventNavigation) {
      evt.preventDefault();
    }
  };

  if (style === 'MenuLogo') {
    return (
      <UnstyledLink
        to={href}
        className={`menu-swimlane__item menu-swimlane__item__btn--logo ${selectedClass}`}
        title={name}
        onClick={onClickLink}>
        <div className="menu-swimlane__item__img-menulogo">
          <AssetImage
            ratio="square"
            imageSet={
              image
                ? getImagePackSet({
                    url: image,
                    variations: [{ width: 300, breakpoint: Breakpoints.DEFAULT }],
                  })
                : []
            }
            alt={name}
          />
          <H3 lookLike="title-4" className="menu-swimlane__item__title">
            {name}
          </H3>
        </div>
      </UnstyledLink>
    );
  }

  if (style === 'MenuThumbnail') {
    return (
      <UnstyledLink
        to={href}
        className={`menu-swimlane__item menu-swimlane__item__btn menu-swimlane__item__btn--thumbnail ${selectedClass}`}
        title={name}
        onClick={onClickLink}>
        <AssetImageContainer className="menu-swimlane__item__btn--thumbnail__image-container">
          <AssetImage
            imageSet={
              image
                ? getImagePackSet({
                    url: image,
                    variations: [{ width: 300, breakpoint: Breakpoints.DEFAULT }],
                  })
                : []
            }
            alt={name}
          />
        </AssetImageContainer>
        <div className="menu-swimlane__item__title__wrapper">
          <H3 lookLike="title-4" className="menu-swimlane__item__title">
            {name}
          </H3>
        </div>
      </UnstyledLink>
    );
  }

  if (style === 'MenuText') {
    return (
      <UnstyledLink
        aria-current={selected ? 'page' : undefined}
        className={`menu-swimlane__item menu-swimlane__item__text ${selectedClass}`}
        to={href}
        onClick={onClickLink}>
        <H3 lookLike="title-4">{name}</H3>
      </UnstyledLink>
    );
  }

  return null;
};
