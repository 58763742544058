import config from '@rikstv/play-common/src/config';
import { getLogger } from '@rikstv/play-common/src/utils/logger/logger';

const log = getLogger('[vergic]');

declare global {
  interface Window {
    psPlugin: any;
    vngage: any;
  }
}

export const applyVergicScript = () => {
  const vergicScript = document.querySelector('[data-vergic]');
  if (!window.psPlugin && !vergicScript) {
    (function (doc) {
      const script = doc.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `${config.vergic.url}/${config.vergic.psId}/ps.js`;
      script.setAttribute('data-vergic', '');
      doc.head.appendChild(script);
      log.info('Script applied');
    })(document);
  } else {
    log.info('Script already applied, skipping');
  }
};
