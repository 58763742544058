import { FC, useCallback, useState } from 'react';
import CoreScroll from '@nrk/core-scroll';

import { ListScrollerSection } from '@rikstv/play-common/src/components/swimlane/listScroller/ListScroller';
import { MenuDetails, SwimlaneItem } from '@rikstv/play-common/src/forces/assetDetails/assetDetails.types';
import { useWindowSize } from '@rikstv/play-common/src/hooks/useWindowSize';
import { useSetScrollRestoration } from '@rikstv/play-common/src/router/useScrollRestoration';
import { isDevFeatureEnabled } from '@rikstv/play-common/src/utils/featureFlags/devFeatureFlags';
import { RequiredProps } from '@rikstv/play-common/src/utils/types/typeUtils';
import { Gutter, useIntersectionObserver } from '@rikstv/shared-components';

import { FadeIn } from '../../../components/animation/fade-in/FadeIn';
import { SwimlanePlaceholder } from '../../../components/swimlane/swimlanePlaceholder/SwimlanePlaceholder';
import { useSwimlane } from '../../../components/swimlane/useSwimlane';
import { getPlaceholderHeight } from '../../../components/swimlane/utils/getPlaceholderHeight';
import { CoverPageGrid } from '../../coverPage/grid/CoverPageGrid';
import { Swimlane } from '../../pages/types';
import { useGetCollectionUrl } from '../../pages/useCollectionUrl';
import { ItemCountReporterFunction } from '../../pages/useEmptyCollectionReporting';
import { HeroSwimlaneItem } from '../heroSwimlane/HeroSwimlaneItem';
import { HeroListScroller } from '../listScroller/HeroListScroller';
import { ContinueWatchingItem } from '../styles/ContinueWatchingItem';
import { FeaturedItem } from '../styles/FeaturedItem';
import { LiveChannelItem } from '../styles/LiveChannelItem';
import { MenuSwimlaneItem } from '../styles/MenuSwimlaneItem';
import { StandardItem } from '../styles/StandardItem';
import { SuperHeroSwimlane } from '../superHeroSwimlane/SuperHeroSwimlane';

import { useImpressionTracking } from './useImpressionTracking';
import { useReportPlayExternalAsset } from './useReportPlayExternalAsset';

export type MenuOrSwimlaneItem = MenuDetails | SwimlaneItem;

export interface CoreScrollSwimlaneProps {
  swimlane: Swimlane;
  swimlaneIndex?: number;
  onItemClicked?: (item: MenuOrSwimlaneItem, index: number) => void;
  enableImpressionTracking?: boolean;
  reportSwimlaneItems: ItemCountReporterFunction;
}

export const CoreScrollSwimlane: FC<CoreScrollSwimlaneProps> = ({
  swimlane,
  swimlaneIndex = 0,
  onItemClicked,
  enableImpressionTracking = true,
  reportSwimlaneItems,
}) => {
  const { setRestoreScrollPoint } = useSetScrollRestoration();
  const [isInViewport, setIsInViewport] = useState(false);
  const { swimlaneItems, menuItems, error, filterSwimlaneItemById } = useSwimlane(swimlane, isInViewport);
  const { setVisibleItems, reportSwimlaneClick } = useImpressionTracking(
    swimlaneItems,
    menuItems,
    swimlane,
    swimlaneIndex,
    enableImpressionTracking
  );
  const reportExternalAppLaunch = useReportPlayExternalAsset();
  const nrOfItems = (swimlaneItems?.length ?? 0) + (menuItems?.length ?? 0);
  const onClick = useCallback(
    (item: MenuOrSwimlaneItem, elementIndex: number) => {
      reportSwimlaneClick(item, elementIndex);
      onItemClicked?.(item, elementIndex);
      setRestoreScrollPoint(swimlane.id, elementIndex);
      reportExternalAppLaunch(item);
    },
    [reportSwimlaneClick, onItemClicked, setRestoreScrollPoint, swimlane.id, reportExternalAppLaunch]
  );

  const fallback = () => setIsInViewport(true);
  const [swimlaneTriggerRef] = useIntersectionObserver(
    list => {
      if (list.some(item => item.isIntersecting) && !isInViewport) {
        setIsInViewport(true);
      }
    },
    { rootMargin: '250px' },
    fallback
  );
  useCallback(() => {
    if (isInViewport) {
      reportSwimlaneItems(swimlane.id, nrOfItems);
    }
  }, [isInViewport, nrOfItems, reportSwimlaneItems, swimlane.id]);

  const getCollectionLink = useGetCollectionUrl();

  const reportIsVisible = useCallback(
    (target: HTMLElement) => {
      const items = menuItems ?? swimlaneItems ?? [];
      const scroller = target as unknown as CoreScroll['el'];
      const elements = scroller.items;

      const visibleElements = elements
        .map((element, idx) => {
          const elementLeftCordinate = element.getBoundingClientRect().x;
          const windowWidth = window.innerWidth;

          if (elementLeftCordinate <= windowWidth - element.offsetWidth) {
            return items[idx];
          }
        })
        .filter((element): element is SwimlaneItem | MenuDetails => !!element);

      setVisibleItems(currentVisibleItems => {
        if (currentVisibleItems.length !== visibleElements.length) {
          return visibleElements;
        }
        // if all elements in new array exist in old, return old to avoid re-render
        if (visibleElements.every(e => currentVisibleItems.includes(e))) {
          return currentVisibleItems;
        }
        return visibleElements;
      });
    },
    [menuItems, setVisibleItems, swimlaneItems]
  );

  // Return null on error or when returned list has zero items
  if (error || swimlaneItems?.length === 0 || menuItems?.length === 0) {
    return null;
  }

  if (swimlaneItems === undefined && menuItems === undefined) {
    return (
      <SwimlanePlaceholder
        ref={swimlaneTriggerRef}
        animationDelay={swimlaneIndex}
        height={getPlaceholderHeight(swimlane.style)}
      />
    );
  }

  const isMenuItems = swimlane.type === 'Menu' && menuItems;
  const isSwimmingPool =
    (swimlane.style === 'SwimmingPool' && swimlaneItems) ||
    (swimlane.style === 'SwimmingPoolPortrait' && swimlaneItems);
  const isFeaturedItems = swimlane.style === 'Featured' && swimlaneItems;
  const isHeroItems = swimlane.style === 'Hero' && swimlaneItems;
  const isLiveChannels = swimlane.type === 'OnTvNow' && swimlane.style === 'Live' && swimlaneItems;
  const isStandardItems =
    !isFeaturedItems && !isHeroItems && !isLiveChannels && swimlane.type === 'Default' && swimlaneItems;
  const isContinueWatchingItems = swimlane.type === 'ContinueWatching' && swimlaneItems;
  const isMyListItems = swimlane.type === 'MyList' && swimlaneItems;
  const isPortrait = swimlane.style === 'StandardPortrait' || swimlane.style === 'SwimmingPoolPortrait';

  if (isHeroItems) {
    return (
      <FadeIn animationDelay={swimlaneIndex}>
        {isDevFeatureEnabled('superHero') && swimlaneIndex === 0 ? (
          <SuperHeroSwimlane swimlane={swimlane} swimlaneItems={swimlaneItems} />
        ) : (
          <HeroMapper
            swimlane={swimlane}
            swimlaneItems={swimlaneItems}
            onClick={onClick}
            reportIsVisible={reportIsVisible}
          />
        )}
      </FadeIn>
    );
  }

  if (isSwimmingPool) {
    return (
      <Gutter>
        <CoverPageGrid portrait={isPortrait}>
          {swimlaneItems.map((item, index) => {
            if (isPortrait) {
              return (
                <StandardItem
                  portrait={isPortrait}
                  asset={item}
                  noMargin
                  swimlaneType={'Default'}
                  key={swimlane.id + item.id}
                  onClick={() => onClick(item, index)}
                />
              );
            }

            return (
              <FeaturedItem
                asset={item}
                noMargin
                showDescription
                swimlaneType={'Default'}
                key={swimlane.id + item.id}
                onClick={() => onClick(item, index)}
              />
            );
          })}
        </CoverPageGrid>
      </Gutter>
    );
  }

  return (
    <FadeIn animationDelay={swimlaneIndex}>
      <ListScrollerSection reportVisibility={reportIsVisible} swimlane={swimlane} animate={!!isLiveChannels}>
        {/* menu items */}
        {isMenuItems &&
          menuItems.map((item, index) => (
            <MenuSwimlaneItem
              key={swimlane.id + item.id}
              {...item}
              href={getCollectionLink({ slug: item.slug })}
              style={swimlane.style}
              onClick={() => onClick(item, index)}
            />
          ))}
        {/* standard items */}
        {isStandardItems &&
          swimlaneItems.map((item, index) => (
            <StandardItem
              key={'standard-item-' + item.id}
              asset={item}
              swimlaneType={swimlane.type}
              onClick={() => onClick(item, index)}
              noMargin
              portrait={isPortrait}
            />
          ))}

        {/* featured items */}
        {isFeaturedItems &&
          swimlaneItems.map((item, index) => (
            <FeaturedItem
              key={swimlane.id + item.id}
              asset={item}
              swimlaneType={swimlane.type}
              onClick={() => onClick(item, index)}
              noMargin
            />
          ))}

        {/* live channel items */}
        {isLiveChannels &&
          (swimlaneItems as RequiredProps<SwimlaneItem, 'channel'>[]).map((item, index) => (
            <LiveChannelItem asset={item} key={swimlane.id + item.id} onClick={() => onClick(item, index)} />
          ))}

        {/* continue watching items */}
        {isContinueWatchingItems &&
          swimlaneItems.map((item, index) => (
            <ContinueWatchingItem
              swimlane={swimlane}
              key={swimlane.id + item.id}
              asset={item}
              preventNavigation={false}
              setOptimisticFilter={filterSwimlaneItemById}
              onClick={() => onClick(item, index)}
            />
          ))}

        {/* MyList items */}
        {isMyListItems &&
          swimlaneItems.map((item, index) => (
            <StandardItem
              key={swimlane.id + item.id}
              asset={item}
              swimlaneType={swimlane.type}
              onClick={() => onClick(item, index)}
              noMargin
            />
          ))}
      </ListScrollerSection>
    </FadeIn>
  );
};

const HeroMapper: FC<{
  swimlane: Swimlane;
  swimlaneItems: SwimlaneItem[];
  onClick: (item: MenuOrSwimlaneItem, elementIndex: number) => void;
  reportIsVisible?: (target: HTMLElement) => void;
}> = ({ swimlane, swimlaneItems, onClick, reportIsVisible }) => {
  const { width } = useWindowSize();

  return (
    <HeroListScroller swimlane={swimlane} reportIsVisible={reportIsVisible} secondsBeforeScroll={8}>
      {swimlaneItems.map((item, index) => (
        <HeroSwimlaneItem
          key={swimlane.id + item.id}
          item={item}
          windowWidth={width}
          onClick={() => onClick(item, index)}
        />
      ))}
    </HeroListScroller>
  );
};
