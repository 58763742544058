import { SwimlaneStyle } from 'ContentLayout';

import { currentBreakpoint } from '@rikstv/play-common/src/components/grid/breakpoints';

import { swimlaneConfig as heroConfig } from '../heroSwimlane/HeroSwimlaneItem';
import { swimlaneConfig as featuredConfig } from '../styles/FeaturedItem';
import { swimlaneConfig as liveChannelConfig } from '../styles/LiveChannelItem';
import { swimlaneConfig as menuConfig } from '../styles/MenuSwimlaneItem';
import { standardConfig, standardPortraitConfig } from '../styles/StandardItem';
import { SwimlaneSettings } from '../Swimlane.types';

const getBreakPoint = ({ placeholderHeight }: Pick<SwimlaneSettings, 'placeholderHeight'>): number =>
  placeholderHeight[currentBreakpoint(window.innerWidth)];

export const getPlaceholderHeight = (style: SwimlaneStyle) => {
  switch (style) {
    case 'Featured':
      return getBreakPoint(featuredConfig);
    case 'Live':
      return getBreakPoint(liveChannelConfig);
    case 'Hero':
      return getBreakPoint(heroConfig);
    case 'MenuLogo':
      return getBreakPoint(menuConfig.MenuLogo);
    case 'MenuText':
      return getBreakPoint(menuConfig.MenuText);
    case 'MenuThumbnail':
      return getBreakPoint(menuConfig.MenuThumbnail);
    case 'StandardPortrait':
      return getBreakPoint(standardPortraitConfig);
    case 'SwimmingPool':
      return 5000;
    case 'SwimmingPoolPortrait':
      return 5000;
    default:
      return getBreakPoint(standardConfig);
  }
};
